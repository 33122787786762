import React, { useState, useEffect } from 'react';
import { Row, Col, Dropdown, Card, Form } from 'react-bootstrap';
import { ResponseResult, MovementList, Invoice, Account, Project } from '../../types';
import { request } from '../../services/Request';
import { addDays } from 'date-fns';
import MovementTable from '../../components/MovementTable';
import Swal from 'sweetalert2';

function ReportMovementList(){

    const [movements, setMovements] = useState<MovementList[]>([]);
    const [totalProjects, setTotalProjects] = useState(0);
    const [start, setStart] = useState<Date>();
    const [end, setEnd] = useState<Date>();
    const [text, setText] = useState('');
    const [account, setAccount] = useState();
    const [project, setProject] = useState();
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [projects, setProjects] = useState<Project[]>([]);
    const [toggleExchange, setToggle] = useState(false);

    useEffect(()=>{
        getAccounts();
        getProjects();
    },[]);

    const getAccounts = () => {
        request('/api/accounts',{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setAccounts(result.data);
            }
        })
    }

    const getProjects = () => {
        request('/api/project',{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setProjects(result.data);
            }
        })
    }

    const getMovements = () => {
        Swal.fire("Movimientos","Buscando...");
        Swal.showLoading();
        request('/api/movement?project='+project+'&account='+account+'&text='+text+'&start='+(start ? start.toISOString() : '')+'&end='+( end ? new Date(end).toISOString() : ''),{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setMovements(result.data.data);
                setTotalProjects(result.data.total_projects);
                setToggle(true);
            }else{
                setMovements([]);
            }
            Swal.close();
        })
    }    

    const report = () => {
        Swal.fire("Movimientos","Enviando correo...");
        Swal.showLoading();
        request('/api/movement/report?project='+project+'&account='+account+'&text='+text+'&start='+(start ? start.toISOString() : '')+'&end='+( end ? new Date(end).toISOString() : ''),{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                Swal.fire("Reporte", "El reporte se ha enviado a su cuenta de correo", "success");
            }else{
                Swal.fire("Reporte", "Ocurrió un error al generar su reporte", "error");
            }
        })
        .catch((error)=>{

        });
    }

    const reportPDF = () => {
        Swal.fire("Movimientos","Enviando correo...");
        Swal.showLoading();
        request('/api/movement/reportpdf?project='+project+'&account='+account+'&text='+text+'&start='+(start ? start.toISOString() : '')+'&end='+( end ? new Date(end).toISOString() : ''),{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                Swal.fire("Reporte", "El reporte se ha enviado a su cuenta de correo", "success");
            }else{
                Swal.fire("Reporte", "Ocurrió un error al generar su reporte", "error");
            }
        })
        .catch((error)=>{

        });
    }

    const onChange = (e:any) => {
        if(e.target.name == 'start'){
            setStart( new Date(e.target.value) );
        }else if(e.target.name == 'end'){
            setEnd( new Date(e.target.value) );
        }else if(e.target.name == 'text'){
            setText(e.target.value);
        }else if(e.target.name == 'account'){
            setAccount(e.target.value);
        }else if(e.target.name == 'project'){
            setProject(e.target.value);
        }
    }

    return (
        <div id="movement-list">
            <Row>
                <Col xs={12} md={{span:10,offset:1}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>
                                Reporte de movimientos
                            </Card.Title>
                            <Row className="align-items-end">
                                <Col xs={12} md={{span:4}}>
                                    <Form.Group controlId="start">
                                        <Form.Label>Desde</Form.Label>
                                        <Form.Control type="date" placeholder="" name="start" onChange={onChange}/>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={{span:4}}>
                                    <Form.Group controlId="end">
                                        <Form.Label>Hasta</Form.Label>
                                        <Form.Control type="date" placeholder="" name="end" onChange={onChange}/>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={{span:4}}>
                                    <Form.Group controlId="text">
                                        <Form.Label>Por nombre</Form.Label>
                                        <Form.Control type="input" placeholder="" name="text" onChange={onChange}/>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={{span:4}}>
                                    <Form.Group controlId="account">
                                        <Form.Label>Por cuenta</Form.Label>
                                        <Form.Select name="account" onChange={onChange} required>
                                            <option></option>
                                            {
                                                accounts.map((account:Account,key:number)=>(
                                                    <option value={account._id}>{account.name}</option>
                                                ))
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={{span:4}}>
                                    <Form.Group controlId="project">
                                        <Form.Label>Por proyecto</Form.Label>
                                        <Form.Select name="project" onChange={onChange} required>
                                            <option></option>
                                            {
                                                projects.map((project:Project,key:number)=>(
                                                    <option value={project._id}>{project.name}</option>
                                                ))
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={{span:2}}>
                                    <button className="btn btn-primary w-100" onClick={getMovements}>
                                        Buscar
                                    </button>
                                </Col>
                                {
                                    toggleExchange && (
                                        <React.Fragment>
                                            <Col xs={12} md={{span:2}}>
                                                <button className="btn btn-primary w-100" onClick={report}>
                                                    Enviar Excel a correo
                                                </button>
                                                {/*
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                                        Enviar a correo
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={report}>Excel</Dropdown.Item>
                                                        <Dropdown.Item onClick={reportPDF}>PDF</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                */}
                                            </Col>
                                        </React.Fragment>
                                    )
                                }
                            </Row>
                            <MovementTable movements={movements} totalProjects={totalProjects}></MovementTable>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ReportMovementList;
